<template>
  <div>
    <h2 class="mb-5">Molar Volume of a Gas: Data & Calculations</h2>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <tbody>
          <tr>
            <td rowspan="2" style="font-weight: bold; text-align: left">Property</td>
            <td colspan="2" style="font-weight: bold; text-align: center">10 mg Trials</td>
            <td colspan="2" style="font-weight: bold; text-align: center">15 mg Trials</td>
            <td colspan="2" style="font-weight: bold; text-align: center">20 mg Trials</td>
          </tr>
          <tr>
            <td style="font-weight: bold; text-align: center">1</td>
            <td style="font-weight: bold; text-align: center">2</td>
            <td style="font-weight: bold; text-align: center">1</td>
            <td style="font-weight: bold; text-align: center">2</td>
            <td style="font-weight: bold; text-align: center">1</td>
            <td style="font-weight: bold; text-align: center">2</td>
          </tr>
          <tr>
            <td>Mass of Mg (g)</td>
            <td
              v-for="answer in answers"
              class="py-2 px-2 mx-0 centered-input"
              :key="answer.massMg"
            >
              <calculation-input
                v-model="inputs[answer.massMg]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Flask Volume (mL)</td>
            <td
              v-for="answer in answers"
              class="py-2 px-2 mx-0 centered-input"
              :key="answer.flaskVol"
            >
              <calculation-input
                v-model="inputs[answer.flaskVol]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Max pressure (kPa)</td>
            <td
              v-for="answer in answers"
              class="py-2 px-2 mx-0 centered-input"
              :key="answer.maxPressure"
            >
              <calculation-input
                v-model="inputs[answer.maxPressure]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{P}_\text{i}~\text{(kPa)}$" /></td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.pInit">
              <calculation-input
                v-model="inputs[answer.pInit]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\Delta\text{P}~\text{(kPa)}$" /></td>
            <td
              v-for="answer in answers"
              class="py-2 px-2 mx-0 centered-input"
              :key="answer.deltaP"
            >
              <calculation-input
                v-model="inputs[answer.deltaP]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{T}~(^\circ\text{C)}$" /></td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.TinC">
              <calculation-input
                v-model="inputs[answer.TinC]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{T}~\text{(K)}$" /></td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.TinK">
              <calculation-input
                v-model="inputs[answer.TinK]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Moles of Mg used (mol)</td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.molMg">
              <calculation-input
                v-model="inputs[answer.molMg]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Moles of H<sub>2</sub> formed</td>
            <td v-for="answer in answers" class="py-2 px-2 mx-0 centered-input" :key="answer.molH2">
              <calculation-input
                v-model="inputs[answer.molH2]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Volume of gas at STP (mL)</td>
            <td
              v-for="answer in answers"
              class="py-2 px-2 mx-0 centered-input"
              :key="answer.volGasSTP"
            >
              <calculation-input
                v-model="inputs[answer.volGasSTP]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Molar Volume of H<sub>2</sub> (L/mol)</td>
            <td
              v-for="answer in answers"
              class="py-2 px-2 mx-0 centered-input"
              :key="answer.molVol"
            >
              <calculation-input
                v-model="inputs[answer.molVol]"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-3 mt-4">
      What is the average molar volume at STP that you determined during this experiment?
    </p>

    <calculation-input
      v-model="inputs.avgMolVol"
      prepend-text="$\text{Average Molar Volume:}$"
      append-text="$\text{L/mol}$"
      :disabled="!allowEditing"
    />

    <p class="mb-3 mt-4">
      What is the % error with respect to the molar volume of a gas at STP that you determined?
    </p>

    <calculation-input
      v-model="inputs.pctError"
      prepend-text="$\text{Percent Error:}$"
      append-text="$\%$"
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'OleMissMolarVolumeGasReportSheet',
  components: {
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        flaskVol: null,

        massMgT1: null,
        maxPT1: null,
        pInitT1: null,
        deltaPT1: null,
        TinCT1: null,
        TinKT1: null,
        molMgT1: null,
        molH2T1: null,
        volGasSTPT1: null,
        molVolT1: null,

        massMgT2: null,
        maxPT2: null,
        pInitT2: null,
        deltaPT2: null,
        TinCT2: null,
        TinKT2: null,
        molMgT2: null,
        molH2T2: null,
        volGasSTPT2: null,
        molVolT2: null,

        massMgT3: null,
        maxPT3: null,
        pInitT3: null,
        deltaPT3: null,
        TinCT3: null,
        TinKT3: null,
        molMgT3: null,
        molH2T3: null,
        volGasSTPT3: null,
        molVolT3: null,

        massMgT4: null,
        maxPT4: null,
        pInitT4: null,
        deltaPT4: null,
        TinCT4: null,
        TinKT4: null,
        molMgT4: null,
        molH2T4: null,
        volGasSTPT4: null,
        molVolT4: null,

        massMgT5: null,
        maxPT5: null,
        pInitT5: null,
        deltaPT5: null,
        TinCT5: null,
        TinKT5: null,
        molMgT5: null,
        molH2T5: null,
        volGasSTPT5: null,
        molVolT5: null,

        massMgT6: null,
        maxPT6: null,
        pInitT6: null,
        deltaPT6: null,
        TinCT6: null,
        TinKT6: null,
        molMgT6: null,
        molH2T6: null,
        volGasSTPT6: null,
        molVolT6: null,

        avgMolVol: null,
        pctError: null,
      },
      answers: [
        {
          massMg: 'massMgT1',
          flaskVol: 'flaskVol',
          maxPressure: 'maxPT1',
          pInit: 'pInitT1',
          deltaP: 'deltaPT1',
          TinC: 'TinCT1',
          TinK: 'TinKT1',
          molMg: 'molMgT1',
          molH2: 'molH2T1',
          volGasSTP: 'volGasSTPT1',
          molVol: 'molVolT1',
        },
        {
          massMg: 'massMgT2',
          flaskVol: 'flaskVol',
          maxPressure: 'maxPT2',
          pInit: 'pInitT2',
          deltaP: 'deltaPT2',
          TinC: 'TinCT2',
          TinK: 'TinKT2',
          molMg: 'molMgT2',
          molH2: 'molH2T2',
          volGasSTP: 'volGasSTPT2',
          molVol: 'molVolT2',
        },
        {
          massMg: 'massMgT3',
          flaskVol: 'flaskVol',
          maxPressure: 'maxPT3',
          pInit: 'pInitT3',
          deltaP: 'deltaPT3',
          TinC: 'TinCT3',
          TinK: 'TinKT3',
          molMg: 'molMgT3',
          molH2: 'molH2T3',
          volGasSTP: 'volGasSTPT3',
          molVol: 'molVolT3',
        },
        {
          massMg: 'massMgT4',
          flaskVol: 'flaskVol',
          maxPressure: 'maxPT4',
          pInit: 'pInitT4',
          deltaP: 'deltaPT4',
          TinC: 'TinCT4',
          TinK: 'TinKT4',
          molMg: 'molMgT4',
          molH2: 'molH2T4',
          volGasSTP: 'volGasSTPT4',
          molVol: 'molVolT4',
        },
        {
          massMg: 'massMgT5',
          flaskVol: 'flaskVol',
          maxPressure: 'maxPT5',
          pInit: 'pInitT5',
          deltaP: 'deltaPT5',
          TinC: 'TinCT5',
          TinK: 'TinKT5',
          molMg: 'molMgT5',
          molH2: 'molH2T5',
          volGasSTP: 'volGasSTPT5',
          molVol: 'molVolT5',
        },
        {
          massMg: 'massMgT6',
          flaskVol: 'flaskVol',
          maxPressure: 'maxPT6',
          pInit: 'pInitT6',
          deltaP: 'deltaPT6',
          TinC: 'TinCT6',
          TinK: 'TinKT6',
          molMg: 'molMgT6',
          molH2: 'molH2T6',
          volGasSTP: 'volGasSTPT6',
          molVol: 'molVolT6',
        },
      ],
      standardizationTitrationCurve: [],
      unknownTitrationCurve: [],
    };
  },
  computed: {
    attachments() {
      return [...this.standardizationTitrationCurve, ...this.unknownTitrationCurve];
    },
  },
};
</script>

<style></style>
